import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Footer, WhatsApp } from '~/components'
import { certificates } from '~/data'
import * as st from '~/assets/styl/Certificates.module.styl'

const Certificates = ({ location }: PageProps) => {
  return (
    <>
      <Head
        location={location}
        title={`Certificações - ${process.env.GATSBY_SITE_NAME}`}
      />
      <section className={st.core}>
        <div className={st.container}>
          <h1>
            Um time e <br /> muitas conquistas.
          </h1>
          <h2>O diferencial Aikon em proporcionar excelência.</h2>
          <div className={st.text}>
            <p>
              A qualidade alto padrão de nossos serviços e obras está presente
              em cada detalhe do profissionalismo da Aikon Empreendimentos.
            </p>
            <br />
            <p>
              Olhamos para as necessidades dos nossos clientes, que buscam por
              conforto e modernidade na escolha do seu investimento. Uma escolha
              inteligente e alinhada com o que existe de melhor.
            </p>
            <br />
            <p>
              Temos a confiabilidade e responsabilidade no desenvolvimento dos
              nossos empreendimentos, desde a concepção à entrega da obra, além
              da constância em melhoria de todos os nossos processos de gestão e
              atendimento.
            </p>
            <br />
            <p>
              A nossa história vem de um profissionalismo que atua há 14 anos no
              segmento da construção civil e imobiliário no sul do Brasil.
              Unindo parcerias experientes, tecnologia, qualidade e visão
              social, garantindo assim, a segurança do que se constrói.
            </p>
            <br />
            <p>
              Somos uma equipe especializada em promover os melhores resultados
              que gera o efeito do trabalho comprometido e aprovado em
              auditorias internas e externas, por meio de Certificações na
              Construção Civil.
            </p>
          </div>
          <p>
            <b>Abaixo algumas das nossas certificações:</b>
          </p>
          <ul>
            {certificates.map(
              ({ title, image, width, height, description }, key) => (
                <li key={key}>
                  <div className={st.left}>
                    <img
                      src={image}
                      width={width}
                      height={height}
                      alt={`Selo da certificação ${title} - Aikon Empreendimentos`}
                    />
                  </div>
                  <section>
                    <h4>{title}</h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></div>
                  </section>
                </li>
              )
            )}
          </ul>
        </div>
      </section>
      <Footer />
      <WhatsApp />
    </>
  )
}

export default Certificates
